<template>
  <div
    class="iframe-youtube"
    :class="{ 'iframe-youtube-mobile': $vuetify.breakpoint.mobile }"
  >
    <iframe
      :src="`https://www.youtube.com/embed/${id}`"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
  </div>
</template>

<script>
export default {
  name: "YouTube",
  props: {
    id: String,
  },
};
</script>

<style scoped>
.iframe-youtube {
  --width: 50%;
  overflow: hidden;
  padding-top: calc(9 / 16 * var(--width)); /*16:9*/
  position: relative;
  margin: 30px auto;
  width: var(--width);
}

.iframe-youtube-mobile {
  --width: 90%;
}

.iframe-youtube iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
