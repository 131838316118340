<template>
  <v-dialog
    v-model="dialog"
    width="80%"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card tile>
      <v-skeleton-loader
        v-if="loading"
        type="article"
      />

      <template v-else>
        <v-card-title
          v-if="!loading"
          class="headline"
        >
          {{
            page.title
          }}
        </v-card-title>

        <you-tube
          v-if="page.youtube"
          :id="page.youtube"
        />

        <v-card-text
          v-if="!loading"
          class="text--primary fill-height page-pop-up__content"
          v-html="page.content"
        />
      </template>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="dialog = false"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import YouTube from "@/components/YouTube";

export default {
  name: "PagePopUp",
  components: {
    YouTube
  },
  props: {
    value: Boolean,
    page: Object,
    loading: Boolean,
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped>
.v-card__title {
  word-break: break-word;
}
.page-pop-up__content {
  overflow: hidden;
}
.page-pop-up__content >>> img {
  max-height: 500px;
  max-width: 100%;
  height: auto !important;
  width: auto !important;
}
</style>
